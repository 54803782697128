/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/* @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.0/font/bootstrap-icons.css"); */
/* @import '~bootstrap/dist/css/bootstrap.min.css'; */

html, body { height: 100%; }
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

a {
  cursor: pointer;
  color: #865a33;
}

a:hover {
  color: #c29064;
}

*::selection {
  background-color: #debfa4;
  color: white;
}

.delete-text:hover {
  color: red
}

.btn-success {
  background-color: #6ca689;
}

.btn-primary {
  background-color: #65a9cd;
  border-color: #65a9cd;
}

.mat-snack-bar-container {
  background: #6ca689 !important;
  color: white;
}

.mat-snack-bar-container button {
  color: black;
}


.mat-snack-bar-container button:hover {
  color: white;
  background-color: #5d9177;
}

.text-link {
  color: blue;
  cursor: pointer;
}

.text-link:hover {
  text-decoration: underline;
}

.text-link-hover:hover {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}


.form-switch{
  padding-left: 2.5rem;
}

.modal-fullscreen{
  width: 100vw !important;
  max-width: none !important;
  height: 100% !important ;
  margin: 0 !important;
}


/* Imported */



body {
  height: 100%;
  overflow-x: hidden;
  /* margin: 200px !important;  */
}


.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}


hr {
  height: 1px;
  margin: 1rem 0;
  /* color: inherit; */
  background-color: currentColor;
  border: 0;
  opacity: .25;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
}

input.valid {
  border: 1px #74a195 solid;
  background-color: #74a1951c;
}

input.invalid {
  border: 1px red solid;
  /* background-color: #74a1951c; */
}

/*

  primary color: #74a195
  secondary color: #b49b7d

*/

.green-text {
  color: #74a195;
}


footer > div > span > a {
  color: #808080;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  /* color: #74a195;
  text-shadow: 1px 1px 1px #0000003b; */
}

.btn-primary {
    color: #fff;
    background-color: #74a195;
    border-color: #74a195;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #74a195;
    border-color: #74a195;
}

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #5e877c;
    border-color: #5e877c;
}

.btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #5e877c;
    border-color: #5e877c;
    box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
}


label.link {
  cursor: pointer;
  color: blue
}

.modal-header {
  align-items: center;
}

.navbar-brand, .clickable {
    cursor: pointer;
}


/* Section */

.section {
  border-radius: 10px;
  /* padding: 25px 10px!important; */
  box-shadow: #ccc 0px 0 5px 0px;
}

.section:hover {
  box-shadow: #74a195 0px 0 5px 0px;
}

/* Text link hover */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */

a{
  text-decoration: none;
  color: #74a195;
}

a:hover {
  color: #297662;
}

.nav-link {
  color: inherit;
}
.nav-link:hover {
  color: #297662;
}

.underline{
  cursor: pointer;
  font-weight: 600;
  position: relative;
}

.underline.active {
  color: #74a195 !important
}

.underline::before{
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #74a195;
  transition: width 0.7s cubic-bezier(0.25, 1, 0.5, 1);
}


.action-text {
  cursor: pointer;
  text-decoration: underline;
  color: #308b72;
}

/* Form */

.custom-form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.custom-input-group-text {
  border: none;
}

@media (hover: hover) and (pointer: fine) {
  .underline:hover::before{
    left: 0;
    right: auto;
    width: 100%;
  }
}
